:root {
  --bg: #282c34;
  --header: #fff;
  --link: #61dafb;
  --text: hsla(0, 0%, 100%, 0.88);
}

body {
  text-align: center;
}

.navlink {
  display: inline-block;
  width: 200px;
  height: 35px;
  border: white;
  font-size: 20px;
  background-color: #1d3b58;
  color: white;
}

.cardInfo{
  display: inline-block;
  border: 10px solid black;
  color: black;
  margin-top: 10px;
  text-align: center;
}

.descriptionDiv{
  display: flex;
}
.descriptionCard{
  border: 10px solid black;
  width: auto;
  height: 125px;
  margin-left: 10px;
  margin-right: auto;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
}

.cardImage{
  display: flex;
  border: 10px solid black;
  width: 300px;
  height: 300px;
  margin-left: 50px;
  margin-right: 10px;
  margin-top: 10px;
  object-fit: scale-down;
}

.medicalContactDiv{
  border: 10px solid black;
  display: inline-block;
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  
}

.reportDiv{
  display: inline-block;
  border: 3px solid black;
  text-align: center;
  width:90vw;
}
tr{
  border: 30px solid black
}

.addTimeDiv{
  display: inline-block;
  border: 3px solid black;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  padding: 10px;
}

.actionButtonDiv{
  display: inline-block;
  width: auto;
  height: 40px;
}

.removeTimeDiv{
  display: inline-block;
  border: 3px solid black;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  padding: 10px;
}

.contentDiv{
  border: 3px solid black;
  margin-right: 200px;
  margin-left: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  word-wrap: break-word;
}

.bioDiv{
  display: inline-block;
  border: 10px solid black;
  margin-right: 10px;
  margin-left: 10px;
  width: 60vw;
  height: auto;
}

.employeeCardDiv{
  margin: 30px;
}

.medicationDiv{
  border: 10px solid black;
  display: inline-block;
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}